<template>
  <div class="home">
    <div class="head">
      <div class="left_img">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="right_nav">
        <p v-for="(item,index) in navlist" :key="index" @click="skip(item)">
          <span class="default" :class="$route.meta.title==item.name?'active':''">{{ item.name }}</span><br />
          <span v-show="item.name==$route.meta.title" class="activeTwo">{{ item.english }}</span>
        </p>
      </div>
    </div>
    <div>
      <router-view ref="child" />
    </div>
    <div class="footer">
      <p class="first_p">
        <span v-for="(item,index) in navlist" :key="index" @click="skip(item)">
          <span>{{ item.name }}</span><span v-if="item.name!='关于'">|</span>
        </span>
      </p>
      <p class="second_p">
        <span style="margin-right:80px;">技术支持: 西安港湾软件科技有限公司</span>
        <span>地址: 西安市雁塔区锦业路锦业时代大厦A座2901室</span>
      </p>
      <p class="three_p">
        <span>Copyright © 西安港湾软件科技有限公司版权所有&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>陕icp备19012251号</span>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;智慧城市专家！</span>
      </p>
    </div>
    <div v-show="$route.path=='/index'" ref="back" class="back" @click="goBack">
      <span></span>
      <img src="../assets/top.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      distance: '',
      navlist: [
        {
          path: '/index',
          name: '首页',
          english: 'HOME'
        },
        // {
        //   path: '/serve',
        //   name: '服务',
        //   english: 'SERVE'
        // },
        {
          path: '/scheme',
          name: '方案',
          english: 'SOLUTION'
        },
        {
          path: '/about',
          name: '关于',
          english: 'ABOUT'
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    skip(item) {
      if (item.name == '关于') {
        this.$router.push('/index')
        this.goAbout()
      } else {
        this.$router.push(item.path)
        document.documentElement.scrollTop = 0
      }
    },
    goAbout() {
      this.$nextTick(() => {
        const targetbox = this.$refs.child.child()
        const target = targetbox.offsetTop

        document.documentElement.scrollTop = target
      })
    },
    goBack() {
      document.documentElement.scrollTop = 0
    }
  }

}

</script>
<style lang="less" scoped>
.head{
  width: 1200px;
  height: 100px;
  margin: 0 auto;
  // border: 1px solid blue;
  display: flex;
  align-items:center;
  justify-content:space-between;
  .left_img{
  }
  .right_nav{
    display: flex;
    p{
      width:86px;
      // border: 1px solid blue;
      // margin-left: 50px;
      text-align: center;
      font-size: 18px;
      >span{
        display:inline-block;
      }
      .activeTwo{
        color:#3688E4;
        font-size: 12px;
      }
      .default{
          padding-bottom: 12px;
          border-bottom: 4px solid transparent;
          cursor: pointer;
      }
      .default:hover,.active{
        color:#3688E4;
         padding-bottom: 12px;
         border-bottom: 4px solid #3688E4;
         font-weight: bolder;
      }
    }
  }
}
.footer{
  height: 200px;
  background-color: #424460;
  color:#fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .second_p{
      margin: 20px 0;
  }
  .first_p{
      >span>span:nth-child(1){
        margin: 0 20px;
        cursor: pointer;
      }
  }
  .second_p,.three_p{
    font-size: 14px;
  }

}
.back{
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 150px;
  right: 100px;
  span{
    height:8px;
    background-color: #fff;
  }
}
</style>
